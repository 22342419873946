@use "variables";

#projects {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 100px 0px;
  max-width: 1000px;

  .projectTitle {
    user-select: none;
    padding-top: 40px;
    font-size: 32px;
    color: variables.$light001;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // .content {
  //   width: 600px;
  //   padding: 50px;
  //   h1 {
  //     font-size: 32px;
  //     color: variables.$light;
  //     margin-bottom: 30px;
  //   }
  //   p {
  //     font-weight: 300;
  //     font-size: 16px;
  //     line-height: 25px;
  //     color: variables.$light005;
  //   }
  // }

  .cardGrid {
    // flex-wrap: wrap;
    // list-style: none;
    // //   padding: 0;
    // //   margin: 0;
    // max-width: 1000px;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    list-style: none;
    padding: 0px;
    margin: 50px 0px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    position: relative;
  }

  .card {
    // background: variables.$dark001;
    border-radius: 2px;
    // max-width: 300px;
    // min-width: 300px;
    // min-height: 300px;
    background-color: rgba(variables.$dark002, 0.5);

    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    // flex: 0 0 calc(33.33% - 20px); /* Distribute cards evenly with margin */
    // margin: 10px;
    // padding: 20px;
    // opacity: 0;
    // transition: all 0.2s ease-in-out;
    display: flex;
    // transform: translateY(20px); /* Move cards down a bit */
    transition: opacity 0.2s ease, transform 0.5s ease;
    // border: 1px solid variables.$dark001;

    object {
      margin-top: 20px;
    }
    .cardInnerContainer {
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      text-align: start;

      // box-shadow: 0 10px 30px -15px var(--navy-shadow);

      // -webkit-box-pack: justify;
      // justify-content: space-between;
      // -webkit-box-align: center;
      position: relative;
      height: 100%;
      padding: 2rem 1.75rem;

      overflow: auto;

      h5 {
        margin-top: 20px;
        color: #aebedb;
        font-weight: 700;
      }
      p {
        color: #5e7191;
        font-size: 14px;
        margin-top: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Limit to three lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .technologiesUsedList {
        color: variables.$light002;
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 10px;
        margin-top: 30px;

        li {
          font-size: 12px;
          border-radius: 20px;
          background-color: #ccd6f61a;
          padding: 4px 10px;
          // letter-spacing: 2px;
          // background-color: blueviolet;
        }
      }
    }
  }

  .card:hover {
    background: linear-gradient(
      180deg,
      variables.$dark002 0%,
      variables.$dark002 0%
    );
    transform: translateY(-10px);
    h5 {
      color: variables.$accent;
    }
  }
}

.accentColor {
  color: variables.$accent;
}

.cardVisible {
  opacity: 1; /* Show cards with opacity transition */
  transform: translateY(0); /* Move cards back to their position */
}

.cardInVisible {
  opacity: 0; /* Show cards with opacity transition */
  transform: translateY(0); /* Move cards back to their position */
}

// @media (orientation: portrait), (max-width: variables.$themeWidth) {
//   #projects {
//     .cardGrid {
//       .card {
//         background-color: rgba(variables.$dark002, 0.5);
//       }
//     }
//   }
// }
